/* Base styling for the button */
.space-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: poppins, sans-serif;
  margin: 0 auto;
  width: 18rem; 
  height: 4rem; 
  cursor: pointer;
  backdrop-filter: blur(1rem);
  border-radius: 0.25rem; 
  transition: 0.5s;
  padding: 10px 25px; 
  border: 3px solid transparent; 
  background-origin: border-box;
  background-clip: content-box, border-box;
  white-space: nowrap; 
  background: none; 
  border: 3px solid #afafafa0; 
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.space-btn:hover {
  transform: scale(1.1); 
  border-image: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%) 1; /* Gradient border on hover */
  background: none; 
}

/* Container for text and arrow */
.more-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between text and arrow */
  padding-left: 15px; /* Add padding to the left of the text */
  padding-right: 15px; /* Add padding to the right of the arrow */
}

/* Arrow next to the text */
.arrow-icon {
  width: 16px; 
  height: auto;
  animation: moveUpDown 2s infinite ease-in-out; /* Infinite up-down animation */
}

/* Infinite up-down animation for arrow */
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px); /* Arrow moves */
  }
}
/* Stars From Uiverse.io by StealthWorm */   
#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 0.25rem; 
}

strong {
  z-index: 2;
  font-family: "poppins", sans-serif;
  font-size: 16px; 
  letter-spacing: 3px;
  color: #ffffff;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

/* Media queries for responsiveness */

/* For small screens (mobile) */
@media (max-width: 600px) {
  .space-btn { /* Reduce button size*/
    width: 11rem; 
    height: 3rem; 
    padding: 5px 12px; 
  }

  .arrow-icon {
    width: 12px;
  }

  strong {
    font-size: 12px;
    letter-spacing: 2px; 
  }
}

/* For medium screens (tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .space-btn {
    width: 15rem; 
    height: 3.5rem; 
    padding: 9px 20px;
  }

  .arrow-icon {
    width: 14px; 
  }

  strong {
    font-size: 15px; 
    letter-spacing: 2.5px; 
  }
}

/* For large screens (desktops) */
@media (min-width: 1025px) {
  .space-btn {
    width: 18rem; 
    height: 4rem; 
  }

  .arrow-icon {
    width: 16px; 
  }

  strong {
    font-size: 16px; 
  }
}

@media (max-width: 600px) {
 .space-btn{
  margin-top: 20px;
 }
}
