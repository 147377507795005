/* General Body Styling */
body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    color: #333;
    font-weight: 700;
}

.kitchef {
    margin: 60px 0;
    display: flex; 
    flex-wrap: wrap; 
    gap: 20px; 
    transform: translateY(20px); /* slight vertical move */
    animation: fadeIn 1.5s ease-in-out forwards; /* Trigger animation */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Slight movement */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Element in its final position */
    }
}

/* Content Container */
.kitchef-info {
    width: 65%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    padding: 0;
}

.kitchef-info h3 {
    padding-left: 25px;
    margin-top: 15px;
    font-size: 2.3rem;
    transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
}

/* Apply specific colors to the individual words */
.red {
    color: rgb(220, 0, 115);
}

.yellow {
    color: rgb(255, 176, 2);
}

.green {
    color: rgb(10, 178, 85);
}

.kitchef-info p {
    font-size: 1.3rem;
    line-height: 1.5;
    color: #CECECF;
    padding: 25px;
    font-weight: 400;
    letter-spacing: 1px;
}

.kitchef-left {
    width: 65%; 
    display: flex;
    flex-direction: column;
    gap: 20px; 
}

.about-kitchef {
    padding: 20px;
    background-color: #0B0B0C;
    border: 1px solid #1A1A1A;
    border-radius: 1em;
}

.kitchef-right {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Horizontal Rule Styling */
hr {
    border: 0;
    border-top: 1px solid #1A1A1A;
    margin: 0 20px;
}

/* Tech Stack card */
.tech-stack {
    background-color: #8000ff31;
    border: 1px solid #1A1A1A;
    border-radius: 1em;
    padding: 20px;
}

.tech-stack ul {
    list-style: none;
    padding: 20px;
}

.tech-item {
    display: flex;
    align-items: center;
    padding: 5px;
    transition: transform 0.3s ease;
}

.tech-item:hover {
    transform: scale(1.05);
}

.tech-item img {
    width: 50px;
    height: auto;
}

.tech-info h4 {
    margin-left: 20px;
    padding-top: 15px;
    font-size: 1.3rem;
    font-weight: 500;
    color: #CECECF;
}

/* Image Styling */
.image1 img {
    width: 100%;
    height: auto;
    border-radius: 1em;
}

/* Responsive Styles */
@media (max-width: 850px) {
    .kitchef-info {
        width: 90%;
    }

    .kitchef-info h3 {
        font-size: 3.5vw;
    }

    .kitchef-info p {
        font-size: 2vw;
    }

    .tech-item img {
        width: 40px;
    }

    .tech-info h4 {
        font-size: 1rem;
        margin-left: 12px;
    }
}

@media (max-width: 600px) {
    .kitchef-info {
        flex-direction: column;
        width: 90%;
    }

    .kitchef-left,
    .kitchef-right {
        width: 100%; 
    }

    .tech-stack{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
        margin: 0 auto;
    }

    .tech-stack hr {
        width: 95%;
    }

    .tech-stack ul {
        padding: 5px;
    }

    .tech-item img {
        width: 40px;
    }

    .tech-info h4 {
        font-size: 15px;
    }

    .kitchef-info h3 {
        font-size: 1.2rem;
        padding-left: 0;
    }

    .kitchef-info p {
        font-size: 14px;
        padding: 0;
    }

    .about-kitchef p {
        text-align: left;
        padding-top: 10px;
    }
}
