/* General Body Styling */
body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    color: #333;
    font-weight: 700;
}
/* Default state for each component inside the .NFT container */
.NFT .NFT-item {
    opacity: 0;
    transform: translateY(20px); /* Slight movement */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* When the item is in view, apply the fade-in effect */
.NFT .NFT-item.visible {
    opacity: 1;
    transform: translateY(0); /* Return to normal position */
}


.NFT{
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex; 
    flex-wrap: wrap; 
    gap: 20px; 
    opacity: 0;
    transform: translateY(20px); /* a slight vertical move */
    animation: fadeIn 1.5s ease-in-out forwards; 
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Slight movement */
    }
    100% {
        opacity: 1;
        transform: translateY(0); 
    }
}
 
/* Content Container */
.NFT-info {
    display: flex;
    justify-content: space-between;
    width: 65%; 
    margin: 0 auto;
    align-items: stretch; 
    gap: 20px; 
}

/* Left side (Skills) */
.NFT-left {
    width: 70%; 
    padding-left: 0;
    margin-left: 0;
    text-align: left; 
    display: flex;
    flex-direction: column;
}

/* Right side  */
.NFT-right {
    width: 30%;
    display: flex;
    gap: 20px; 
    flex-direction: column;
    justify-content: space-between;
}


/* Horizontal Rule Styling */
hr {
    border: 0;
    border-top: 1px solid #1A1A1A;
    margin: 0 20px;
}

/* Video Container */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.video-container iframe {
    border-radius: 1em;
    border: none;
}

/* Responsive Styles */
@media (max-width: 850px) {

    .NFT-info {
        width: 90%; 
        gap: 10px; 
    }

    .NFT-right {
        padding-left: 0;
        margin-left: 0;
        text-align: left; 
        display: flex;
        gap: 10px; 
        flex-direction: column;
    }
    .NFT-left {
        padding-bottom: 0;
    }
    
    section p {
        font-size: 2vw;
    }


    .video-container {
        margin-top: 20px;
        padding-bottom: 20px;
    }
}

@media (max-width: 600px) {
    /* Adjust the showcase image and content to take 90% of screen width */
    .NFT-info{
        width: 90%; 
        padding-top: 0;
    }

    /* Stack the content below each other */
    .NFT-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%; 
    }

    .NFT-left {
        width: 100%;
    }

    .NFT-right {
        width: 100%;
    }

    .text-container p {
        font-size: 1.8rem;
        margin: 0;
        text-align: left;
        color: #B6B9C6;
    }

    /* Make the text smaller and adjust layout for mobile */
    .text-container h1 {
        font-size: 2rem;
    }

    header img {
        border-radius: 1em;
        width: 80px;
        margin-right: 30px;
    }
    .text-container h1{
        font-size: 1.8rem;
    }
    .text-container p {
        font-size: 0.6rem;
        width: 100%;
    }

    .NFT-left{
        padding-right: 0;
        margin-left: 0;
    }

    /* Ensure the video container maintains good spacing */
    .video-container {
        margin-top: 20px;
        margin: 20px;
        border-radius: 1em;
        padding-bottom: 20px;
    }

}

