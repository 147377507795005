/* src/App.css */

/* Importing Poppins font globally */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeLegibility;
}

/* Body styles */
body {
  font-family: 'Poppins', sans-serif;
  background-image: url(assets/images/backgrounds/endless-nodes.svg); 
  background-repeat: repeat;
  background-size: auto; /* Keeps the original size of the image */
  color: #ffffff; 
  font-weight: 400;
  line-height: 1.6;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Global link styles */
a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease-in-out;
}


/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
}

/* Utility classes for margins/padding */
.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

/* Utility for centering elements */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global transition for smoother animations */
.transition {
  transition: all 0.3s ease-in-out;
}


button:focus {
  outline: none;
}

/* Adding a default background if needed across pages */
.main-background {
  background-color: #000000;
}

/* Add responsiveness if necessary */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
