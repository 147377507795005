.about-me {
  background-image: url('../assets/images/moon_abstract_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-height: 100vh; /* Set a maximum height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the content */
  padding: 5% 0; /* Adjust padding for better control of section height */
  margin-top: 50px;;
}

.main-about-me{
  margin-top:10%;
}

.about-me img {
  width: 15%;
  z-index: 1;
  margin-bottom: 2%;
  border-radius: 50%; /* Ensure the container is circular */
  transition: filter 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.main-about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-me img:hover {
  filter: drop-shadow(0 0 40px rgba(0, 200, 255, 0.674)) /* Pinkish glow */
          drop-shadow(0 0 60px rgba(255, 255, 255, 0.348)); /* Light blue glow */
}

.about-me-text {
  width: 60%;
  margin: 0 40px;
  
}

.about-me-text h2 {
  color: #c84a97;
  font-family: "Poppins", sans-serif;
  font-size: 2.5vw;
  line-height: 1.565;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  
}

.letMe {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  line-height: 1.565;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.about-me-text p {
  color: rgb(193, 193, 193);
  letter-spacing: 1px;
  line-height: 1.6vw;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 1.1vw;
  padding-top: 20px;
  padding-bottom: 15%;
}

@media (max-width: 768px) {

  .about-me {
    height: auto; 
    padding: 2% 0; /* Less padding for smaller screens */
  }

  .about-me img {
    width: 120px; /* Reduce the image size */
  }

  .about-me-text {
    width: 90%;
    text-align: center; /* Center the text for better layout */
  }

  .about-me-text h2 {
    font-size: 1.5rem;
  }

  .letMe {
    font-size: 1.2rem;  
  }

  .about-me-text p {
    font-size: 14px; 
    line-height: 24px; 
    text-align: center; /* Center the text for readability on tablets */
  }
}


@media (max-width: 400px) {
  .letMe {
    font-size: 1rem;  
  }
}
