/* Video Container */
.video-card {
    position: relative;
    padding-bottom: 30%; /* Controls the height */
    overflow: hidden;
    width: 50%; 
    margin: 0 auto; /* Center the video */
    margin-top: 50px;
    margin-bottom: 60px;
}

.video-card iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; 
    border-radius: 1em;
    border: none;
}

/* For screens below 850px */
@media (max-width: 850px) {
    .video-card {
        width: 60%; 
    }
}

/* For screens below 600px */
@media (max-width: 600px) {
    .video-card {
        width: 70%; 
        padding-bottom: 40%; 
        margin-top:0;
        margin-bottom: 40px;
    }
}
