/* Skill Card Container */
.tech-used {
    width: 100%;
    background-color: #252525a7;
    border: 1px solid #1a1a1a;
    border-radius: 1em;
    overflow: hidden;
    margin: 0 auto; /* Center the container */
    text-align: left;
    padding: 20px;
    height: auto;
    margin-top: 0;
}

/* Skill Progress Bar Body */
.skills-body {
    padding: 20px;
    text-align: left;
}

/* Each Skill Row */
.skill {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 15px; 
    max-width: 400px; 
    margin: 0 auto; /* Center each skill row */
}

/* Skill Name */
.skill-name {
    width: 120px;
    font-size: 16px;
    color: #ffffff;
    text-align: left; 
    transition: transform 0.3s ease; /* Transition for scaling effect */
}

/* Scale the skill name when hovered or touched */
.skill-name:hover {
    transform: scale(1.05); /* Slightly increase the size */
}

/* Skill Progress Bar */
.skill-level {
    width: 200px;
    height: 10px;
    background-color: #777777;
    border-radius: 10px;
    overflow: hidden;
}

/* Skill Percent Bar */
.skill-percent {
    background-color: #65a3ffc7;
    height: 100%;
}

/* Skill Percent Number */
.skill-percent-number {
    margin-left: 10px; 
    color: #d4d4d4;
    text-align: left;
}

/* Divider Line */
#skills-line {
    border: 0;
    border-top: 1px solid #1A1A1A;
    margin: 0 20px; 
}

/* Technologies and Libraries List */
.other ul {
    padding: 0;
    list-style: none;
}

/* Inline-block list items with spacing */
.other li {
    background-color: #393e49;
    padding: 10px 20px;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 10px;
    border-radius: 0.8em;
    font-size: 14px;
    color: #d9d9d9;
    display: inline-block;
    transition: transform 0.3s ease;
}

/* Scale the skill when hovered or touched */
.other li:hover {
    transform: scale(1.05);
}

/* Other Skills Container */
.other {
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 850px) {

    .skill-name {
        font-size: 14px;
        width: 100px;
    }

    .skill-level {
        width: 150px;
    }

    .skill-percent-number {
        font-size: 14px;
    }

    .other li {
        padding: 8px 15px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
}


@media (max-width: 600px) {
    .tech-used {
        margin-left: 0;
        margin-right: 0;
        padding: 6px;
    }

    .skill-name {
        font-size: 12px;
        width: 90px;
    }

    .skill-level {
        width: 130px;
    }

    .skill-percent-number {
        font-size: 12px;
    }

    .other li {
        padding: 6px 12px;
        margin-right: 8px;
        margin-bottom: 8px;
        font-size: 10px;
        margin-right: 5px;
        margin-left: 5px;
    }
}
