body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    margin: 0;
    color: #333;
    background: #030303;
    font-weight: 700;
}

/* Header Section */ 
header {
    color: #fff;
    padding: 0 20px 0px 10px; /* butttom*/
    text-align: left;
}

.header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 17%;
}

header img {
    border-radius: 2em;
    width: 150px;
    height: auto;
    margin-right: 30px;
    margin-bottom: 0;
    transition: transform 0.3s ease;
}

header img:hover {
    transform: scale(1.05);
}

.header-text-container h1 {
    font-size: 3.8rem;
    margin: 0;
    line-height: 1.3;
    text-align: left;
    background: linear-gradient(to bottom, #c2c2c2, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header-text-container {
    transition: transform 0.3s ease;
}

.header-text-container:hover {
    transform: scale(1.05);
}

.header-text-container p {
    font-size: 1.8rem;
    text-align: left;
    margin: 0;
    color: #B6B9C6;
}

/* Project Showcase */
.project-showcase {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.project-showcase img {
    width: 65%;
    height: auto;
    border-radius: 2em;
    /* margin-bottom: 10px; buttom */ 
}

@media (max-width: 1100px) {
    .header-container {
        padding-left: 16%;
    }

    .header-text-container h1 {
        font-size: 2.5rem;
    }

    .header-text-container p {
        font-size: 1.3rem;
    }

    header img {
        border-radius: 1em;
        width: 100px;
        margin-right: 30px;
    }
}

/* For screens below 850px */
@media (max-width: 850px) {
    .header-container {
        padding-left: 8%;
    }

    .header-text-container h1 {
        font-size: 2.5rem;
    }

    .header-text-container p {
        font-size: 1.3rem;
    }

    header img {
        border-radius: 1em;
        width: 100px;
        margin-right: 30px;
    }

    .project-showcase img {
        width: 90%;
    }
}

/* For screens below 600px */
@media (max-width: 600px) {

    header{
        padding: 5px;
    }

    .header-container {
        align-items: left;
        justify-content: Left;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }

    /* Make the text smaller and adjust layout for mobile */

    .header-container img {
        border-radius: 1em;
        width: 100px;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .header-text-container h1{
        font-size: 2rem;
        text-align: center;
        padding:0;
        margin: 0;
    }
    .header-text-container p {
        font-size: 1rem;
        font-weight: 500;
        color: rgb(207, 249, 255);
        width: 100%;
        text-align: center;
    }

    /* Adjust the showcase image and content to take 90% of screen width */
    .project-showcase img{
        width: 90%; 
    }
}