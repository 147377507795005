/* Links Styling */
.github {
    background-color: #6600ff24;
    border: 1px solid #2e2e2e;
    border-radius: 1em;
    height: 100%;
    width: 100%; /* Take up the full width */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.github p {
    color: #CECECF;
    font-size:1.5rem;
    align-items: center;
    font-weight: 600; 
    transition: transform 0.3s ease;
}

.github p:hover {
    transform: scale(1.05);
    color: #c84a97;
}

.github p a{
    padding: 5px;
}

.github img {
    width: 20px;
    height: auto;
    margin-left: 10px;
}

@media (max-width: 1100px) {
    .github img {
        width: 15px;
        margin-left: 5px; 
    }

    .github p {
        font-size: 1rem; 
    }
}

@media (max-width: 600px) {

    .github{
        padding: 10px;
    }
    .github img {
        width: 12px; 
        margin-left: 2px;
    }

    .github p {
        font-size: 1rem; 
    }
}