*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.graphicDesign{
  animation: fadeIn 1.5s ease-in-out forwards; /* Trigger animation */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Slight movement */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Element in its final position */
    }
}

.graphicText{
 padding-top: 60px;
}

body {
  font-family: "Nunito", sans-serif;
  color: #333;
  font-weight: 300;
  background: none;
  line-height: 1.6;
}

.desgin-container {
  width: 65%; 
  margin: 2rem auto;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 equal-width columns */
  grid-template-rows: auto; /* Automatic row height */
  grid-gap: 1.3rem;
  margin: 0 auto;
}
/* Apply transition to all gallery images */
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s, filter 0.5s; /* Added filter transition */
}

/* When the gallery is hovered, apply blur to all images */
.gallery:hover .gallery__img {
  filter: blur(2px); 
}

/* hovered image stays sharp */
.gallery__item:hover .gallery__img {
  filter: none; /* Remove blur for hovered image */
  transform: scale(1.05); /* Keep the scaling effect for hovered image */
}


.gallery__img:hover {
  transform: scale(1.05);
  filter: none; /* Remove blur on hovered image */
}

/* Square images */
.gallery__item--square {
  grid-column: span 2; /* Span 2 columns */
  grid-row: span 2; /* Span 2 rows */
}

/* Wide images */
.gallery__item--wide {
  grid-column: span 4; /* Span 4 columns for wide images */
  grid-row: span 2; /* Span 2 rows */
}

/* Tall images */
.gallery__item--tall {
  grid-column: span 2; /* Span 2 columns */
  grid-row: span 4; /* Span 4 rows */
}

@media (max-width: 850px) {
  .desgin-container {
    width: 90%; 
    
  }
  
}
/* Small screens */
@media (max-width: 600px) {
  .graphicText{
    padding-top: 30px;
   }

  .gallery {
    grid-template-columns: repeat(4, 1fr); /* Switch to 4 columns on small screens */
    grid-gap: 1rem;
    width: 90vw; /* Make it 90% of screen width on smaller devices */
  }

  .gallery__item--square {
    grid-column: span 2; /* Span 2 columns on smaller screens */
    grid-row: span 2;
  }

  .gallery__item--wide {
    grid-column: span 4; /* Span 4 columns for wide images */
    grid-row: span 2;
  }

  .gallery__item--tall {
    grid-column: span 4; /* Span 4 columns for tall images */
    grid-row: span 2;
  }
}
