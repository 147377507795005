.banner {
    width: 100%; 
    display: flex;
    justify-content: center; 
  }

.banner img {
    width: 65%; 
    height: auto; 
    border-radius: 2em;;
    object-fit: cover; 
  }


  @media (max-width: 850px) {
  .banner img{
    width: 90%;
    border-radius: 1em;;
}

}
