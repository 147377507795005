/* Panel containing video background and content */
.mysite-pannel {
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-image: url('../assets/images/pink_blue_background.jpg');
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.8));
}

#myVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  object-fit: cover;
  opacity: 70%;
}

.HelloWorld {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
}

.hello {
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 0;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.name {
  color: white;
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-size: clamp(2rem, 5vw, 7rem); /* Dynamic font-size */
  letter-spacing: 0.1px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.more {
  color: white;
  font-size: clamp(0.8rem, 1vw, 1.2rem);
  font-weight: 700;
  letter-spacing: 0.25rem;
  line-height: 3rem;
  text-transform: uppercase;
  margin: 10px 0;
  border-color: rgba(255, 255, 255, 0.99);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
  white-space: nowrap;
  display: inline-block;
}

.more.stroke {
  background: transparent;
  border: 3px solid #afafafa0;
  padding: 0.7% 5%;
  transition: border-color 0.5s, transform 0.4s ease-in-out;
}

.more:hover {
  border-color: #00d1ff;
  transform: scale(1.05);
}

.more::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 11px;
  background: url('../assets/logos/smallArrow.png') no-repeat;
  background-size: contain;
  margin-left: 8px;
  animation: arrow-bob 2s infinite;
}

/* Animation for arrow */
@keyframes arrow-bob {
  50% {
    transform: translateY(5px);
  }
}

/* Hidden elements for slide-in animation */
.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

/* Media Queries for Responsiveness */

/* For screens smaller than 900px */
@media (max-width: 900px) {
  .hello {
    font-size: 4vw;
  }

  .name {
    font-size: 6vw;
  }

  #myVideo {
    display: none;
  }
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
  .mysite-pannel {
    margin-top: 5%;
    height: 80vh;
  }

  .HelloWorld {
    top: 45%;
    width: 100%;
  }

  .hello {
    font-size: 4vw;
  }

  .name {
    font-size: 8vw;
    white-space: nowrap;
    overflow: hidden;
  }

  .more {
    font-size: 3vw;
    line-height: 2rem;
    padding: 0 3%;
    margin-bottom: 10px;
  }

  .more.stroke {
    padding: 0.8% 8%;
    border: 2px solid #afafafa0;
  }
}
