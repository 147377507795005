/* Footer styles */
.footer {
  width: 100vw;
  height: 10vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
  color: white;
  font-family: 'Poppins', sans-serif;
  margin-top: 150px;
}

/* Gradient animation for the footer */
.gradient {
  background: linear-gradient(334deg, #000000, #37116c, #ff0077);
  background-size: 180% 180%;
  animation: gradient-animation 6s ease infinite;
}

/* Keyframes for the gradient animation */
@keyframes gradient-animation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/* Footer text content styles */
.footer-content {
  z-index: 1;
  position: relative;
  text-align: center;
  font-size: 1.2rem; 
}


@media (max-width: 600px) {
  .footer {
    margin-top: 0;
  }
  
}
