/* Content card */
.content-card {
    width: 65%;
    margin: 0 auto;
    display: flex;
    letter-spacing: 1px;
}

.line{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-description{
    background-color: #0B0B0C;
    border: 1px solid #1A1A1A;
    border-radius: 1em;
    padding: 50px;
    margin-bottom: 0;
}

.content-card h3 {
    color: #CECECF;
    text-align: left;
    font-size: 2.3rem;
    margin-bottom: 0;
}

.content-card p {
    font-size: 1.3rem;
    line-height: 1.5;
    color: #CECECF;
    font-weight: 400;
    text-align: left;
    letter-spacing: 1px;
    padding-bottom: 0; 
    margin-bottom: 0;
}

@media (max-width: 850px) {
    .content-card {
        width: 90%;
        padding-bottom: 0;
        margin-bottom: 0;
        
    }
    
    .content-card p{
        font-size: 2vw;
        padding-top: 0;
        padding: 10px;
    }

    .content-card h3 {
        font-size: 2rem;
        padding: 10px;
        padding-left:15px;
    }

    .card-description {
        
        border-radius: 1em;
    }

    .content-card h3{
        font-size: 3.5vw;
    }

    .line{
        margin-top: 10px;
        margin-bottom:10px;
    }
}

@media (max-width: 600px) {
    body, html {
        padding: 0;
        margin: auto 0;
    }

    .content-card {
        padding: 0;
        box-sizing: border-box; /* Ensures padding stays within the width */
    }

    .content-card h3 {
        font-size: 1.2rem;
        padding: 0;
       
    }

    .content-card p {
        font-size: 14px;
        padding: 0;
    }

    .card-description {
        text-align: left;
        margin-left: 0;
        padding: 30px;
        width: 100%; /* Ensure full width */
        box-sizing: border-box;
    }
}