.carousel-wrapper {
  margin: 0 auto;
  width: 65%;
  padding: 0;
  margin-top: 10%;
  margin-bottom: 10%;
}

.carousel .slide {
  overflow: visible;
  padding: 20px;
  position: relative;
}

.carousel .slide img {
  width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.carousel .legend {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

/* Media Queries */

/* For tablets (max-width 768px) */
@media (max-width: 850px) {
  .carousel-wrapper {
      width: 90%;
  }

  .carousel .legend {
      font-size: 14px;
      padding: 8px;
  }
}

/* For mobile devices (max-width 600px) */
@media (max-width: 600px) {
  .carousel-wrapper {
      width: 90%; 
      padding: 10px;
  }

  .carousel .legend {
      font-size: 12px;
      padding: 6px;
  }

  .carousel .slide img {
      width: 100%; 
  }
}
