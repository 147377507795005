/* Links Card */
.links {
    border-radius: 1em;
    padding: 20px;
    margin-bottom: 0;
    background-color: #6600ff24;
    border: 1px solid #2e2e2e;
    height: auto; 
    margin-top: 0; 
    height: 100%;
}

.links p a {
    font-size: 1.3rem;
    font-weight: 530;
    line-height: 1.5;
    color: #CECECF;
    text-decoration: none;
}
.links p  {
   padding:20px ;
   transition: transform 0.3s ease;
}

.links p:hover {
    transform: scale(1.05);
}

.links p a:hover {
    color: #c84a97;
}


.links span img {
    width: 20px;
    margin-left: 10px;
    margin: 0;
}

@media (max-width: 1400px) {
    .links p {
        padding: 20px;
    }
  }

@media (max-width: 850px) {
    .links p a {
        font-size: 2vw;
        padding-top: 0;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
    }

    .links span img {
        width: 15px;
    }
    .links p {
        padding: 10px;
    }
}


@media (max-width: 600px) {
    .links{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
        margin: 0 auto;
        margin-left: 0;
    }

    .links p a {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        color: #CECECF;
        text-decoration: none;
    }

    .links p {
        padding: 10px;
    }

    .links span img {
        width: 10px;
    }
}